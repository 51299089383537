import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import {
  Box,
  Button,
  Flex,
  H1,
  H4,
  H6,
  RichText,
  Image,
  Text,
  Wrapper,
} from "components"

const Showhome = ({
  title,
  image,
  address,
  type,
  addressCoordinates,
  addressLink,
  headshots,
  email,
  phone,
  hours,
  names,
  roles,
  community,
}) => (
  <article css={css({ "&:not(:last-of-type)": { pb: 5 } })}>
    <Wrapper as="section" pt={5} borderTop="1px solid" borderColor="border">
      {title && <H1 mb={type ? 3 : 4}>{title.text}</H1>}
      {type && <H6 mb={4}>{type}</H6>}
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-3, -4],
          "> *": {
            p: [3, 4],
          },
        })}
      >
        <Box width={["100%", 1 / 2]}>
          {address && addressLink && (
            <Box>
              <Text fontSize={0}>Address</Text>
              <Text>
                <a href={addressLink.url} target="_blank" rel="noopener">
                  {address.text}
                </a>
              </Text>
            </Box>
          )}
          {hours && (
            <Box mt={4}>
              <Text fontSize={0}>Hours of operation</Text>
              <RichText
                content={hours}
                css={css({
                  "*": {
                    m: "0 !important",
                  },
                })}
              />
            </Box>
          )}
          <Flex
            flexWrap="wrap"
            mt={3}
            css={css({ "> *:not(:last-of-type)": { mr: [3, 4] } })}
          >
            {community && (
              <Button
                children={`Community info`}
                mt={3}
                variant="default"
                as={Link}
                to={"/communities/" + community.uid + "/"}
              />
            )}
            {addressLink && (
              <Button
                id={community.uid + "_directions"}
                children={`Directions`}
                mt={3}
                variant="default"
                as="a"
                href={addressLink.url}
                target="_blank"
                rel="noopener"
              />
            )}
          </Flex>
        </Box>
        <Box width={["100%", 1 / 2]}>
          <Flex
            flexWrap="wrap"
            css={css({ "> *:not(:last-of-type)": { mr: [3, 4] } })}
          >
            {headshots &&
              headshots.map((headshot, index) => {
                if (headshot.headshot) {
                  return (
                    <Box
                      width={6}
                      mb={3}
                      key={
                        (community && community.uid) +
                        "showhomeHeadshot" +
                        index
                      }
                    >
                      {headshot.headshot && <Image src={headshot.headshot} />}
                    </Box>
                  )
                } else {
                  return null
                }
              })}
          </Flex>
          <Box mt={3}>
            {names && <H4 mb={2}>{names.text}</H4>}
            {roles && <Text>{roles}</Text>}
            {phone && (
              <Text mt={1}>
                <a children={phone} href={`tel:${phone}`} />
              </Text>
            )}
            {email && (
              <Text mt={1}>
                <a children={email} href={`mailto:${email}`} />
              </Text>
            )}
          </Box>
        </Box>
      </Flex>
    </Wrapper>
    <Wrapper as="section" pt={5}>
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-2, -4],
          "> *": {
            p: [2, 4],
          },
        })}
      >
        {image && (
          <Box width={["100%", 1 / 2]}>
            <Image src={image} />
          </Box>
        )}
        {addressLink && addressCoordinates && (
          <Box width={["100%", 1 / 2]}>
            <Box
              as="a"
              href={addressLink.url}
              target="_blank"
              rel="noopener"
              display="block"
              height="100%"
              bg="text"
              style={{
                backgroundImage:
                  "url(https://maps.googleapis.com/maps/api/staticmap?center=" +
                  addressCoordinates.latitude +
                  "%2c%20" +
                  addressCoordinates.longitude +
                  "&markers=anchor:bottomleft%7Cicon:https%3A%2F%2Fpartnershomes.ca%2Fimages%2F" +
                  (type === "Showhome"
                    ? "showhomepin"
                    : type === "Sales Center"
                    ? "salescenterpin"
                    : "showhomepin") +
                  ".png%7C" +
                  addressCoordinates.latitude +
                  "%2c%20" +
                  addressCoordinates.longitude +
                  "&zoom=14&size=640x640&scale=2&key=" +
                  process.env.GATSBY_GOOGLE_KEY +
                  "&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e)",
              }}
              css={css({
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              })}
            />
          </Box>
        )}
      </Flex>
    </Wrapper>
  </article>
)

export default Showhome
