import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CallToAction, PageHeader, SEO } from "components"
import { usePreviewData } from "utils"

import Showhome from "./_showhome"

function ShowhomesPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicShowhomes {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            closing_block_image {
              url
              alt

              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            closing_block_video {
              url
            }
            closing_block_title {
              text
            }
            closing_block_description {
              html
            }
            closing_block_button_text
            closing_block_button_link {
              link_type
              url
            }
            closing_block_style
            closing_block_pattern
            closing_block_color
            closing_block_side
          }
        }
        showhomes: allPrismicShowhome {
          nodes {
            data {
              image {
                url
                alt

                fluid(maxWidth: 900) {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                text
              }
              showhome_category
              address {
                text
              }
              address_coordinates {
                latitude
                longitude
              }
              address_link {
                url
              }
              hours {
                html
              }
              sales_teams_headshots {
                headshot {
                  url
                  alt

                  fluid(maxWidth: 160) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              sales_teams_names {
                text
              }
              sales_teams_roles
              sales_team_email
              sales_team_phone
              community {
                uid
              }
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicShowhomes, showhomes } = MERGED_DATA
  let previewData = {}
  if (typeof window !== "undefined") {
    previewData = window.__PRISMIC_PREVIEW_DATA__
  }
  // merge preview showhomes in
  let finalShowhomes = []
  if (showhomes.nodes) {
    let found = false
    showhomes.nodes.forEach(showhome => {
      if (
        previewData &&
        previewData.prismicShowhome &&
        previewData.prismicShowhome.data.title &&
        showhome.data.title &&
        previewData.prismicShowhome.data.title.text === showhome.data.title.text
      ) {
        found = true
        finalShowhomes.push(previewData.prismicShowhome)
      } else {
        finalShowhomes.push(showhome)
      }
    })
    //if preview showhome is not published, add it in
    if (previewData && previewData.prismicShowhome && !found) {
      finalShowhomes.unshift(previewData.prismicShowhome)
    }
  }

  return (
    <>
      <SEO
        title={prismicShowhomes.data.seo_title}
        description={prismicShowhomes.data.seo_description}
        image={prismicShowhomes.data.seo_image}
      />
      <PageHeader
        image={prismicShowhomes.data.hero_image}
        video={prismicShowhomes.data.hero_video}
        title={prismicShowhomes.data.hero_title}
        subtitle={prismicShowhomes.data.hero_subtitle}
      />
      {finalShowhomes
        .sort((a, b) => {
          if (a.data.title && b.data.title) {
            return a.data.title.text > b.data.title.text ? 1 : -1
          } else {
            return 0
          }
        })
        .map((showhome, index) => (
          <Showhome
            key={"showhome" + index}
            title={showhome.data.title}
            type={showhome.data.showhome_category}
            image={showhome.data.image}
            hours={showhome.data.hours}
            address={showhome.data.address}
            addressLink={showhome.data.address_link}
            addressCoordinates={showhome.data.address_coordinates}
            headshots={showhome.data.sales_teams_headshots}
            names={showhome.data.sales_teams_names}
            roles={showhome.data.sales_teams_roles}
            email={showhome.data.sales_team_email}
            phone={showhome.data.sales_team_phone}
            community={showhome.data.community}
          />
        ))}
      <CallToAction
        image={prismicShowhomes.data.closing_block_image}
        video={prismicShowhomes.data.closing_block_video}
        heading={prismicShowhomes.data.closing_block_title}
        text={prismicShowhomes.data.closing_block_description}
        button={prismicShowhomes.data.closing_block_button_text}
        link={prismicShowhomes.data.closing_block_button_link}
        style={prismicShowhomes.data.closing_block_style}
        pattern={prismicShowhomes.data.closing_block_pattern}
        bg={prismicShowhomes.data.closing_block_color}
        align={prismicShowhomes.data.closing_block_side}
      />
    </>
  )
}

export default ShowhomesPage
